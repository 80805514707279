const resourceTypes = {
  source: 'Source',
  source_plural: 'Sources',
  dataset: 'Dataset',
  dataset_plural: 'Datasets',
  sync: 'Sync',
  sync_plural: 'Syncs',
};

const resourceErrors = {
  'error-banner-title': 'Last changes are not deployed.',
  'invariant-error-banner-description':
    'Please modify all items in red to deploy your last changes.',
  'eventual-error-banner-description':
    'Please modify all items in yellow to deploy your last changes.',
  'error-banner-more-info': 'More information.',
  'rollback-invalid-changes': 'Roll my last changes back',
  'eventual-error-help-mark':
    'Some modifications are required to be able to deploy your last changes.',
  'invariant-error-help-mark': 'There are errors that need to be fixed.',

  'unknown-error-title': 'Unknown Error',
  'unknown-error-description':
    'An unknown error has occured. Please verify your configuration or contact support for assistance.',
};

const connectionSourceErrors = {
  'source-name-is-empty-title': 'Empty Source Name',
  'source-name-is-empty-description':
    'Name field cannot be empty. Please provide a name for the connection source.',

  'source-name-is-too-long-title': 'Too Long Source Name',
  'source-name-is-too-long-description':
    'Connection source name cannot exceed the maximum length of 30 characters.',

  'source-name-duplicated-title': 'Duplicate Source Name',
  'source-name-duplicated-description':
    'This name is already in use by another connection source. Please choose a unique name.',

  'updated-at-column-type-mismatching-timestamp-type-title': 'Mismatch in "Updated at" Column Type',

  'updated-at-column-type-mismatching-timestamp-type-description':
    'The "Updated at" column should either be of type timestamp or casted to timestamp.',

  'fetching-mode-updated-at-column-type-mismatching-timestamp-type-title':
    'Mismatch in Mode "Updated at" Column Type',
  'fetching-mode-updated-at-column-type-mismatching-timestamp-type-description':
    'Mismatch in Mode "Updated at" Column Type',

  'fetching-mode-updated-at-column-does-not-exist-title':
    '"Updated at" Column has not been mapped but used in the incremental mode for source fetching',
  'fetching-mode-updated-at-column-does-not-exist-description':
    '"Updated at" Column has not been mapped but used in the incremental mode for source fetching',

  'fetching-mode-incremental-updated-at-column-is-empty-title':
    'Empty "Updated at" Column in Incremental Mode',
  'fetching-mode-incremental-updated-at-column-is-empty-description':
    'The "Updated at" column is empty in the incremental mode. Please select a column for the incremental mode.',

  'created-at-column-type-mismatching-timestamp-type-title': 'Mismatch in "Created at" Column Type',
  'created-at-column-type-mismatching-timestamp-type-description':
    'The "Created at" column should either be of type timestamp or casted to timestamp.',

  'source-connection-not-found-title': 'Invalid Connection',
  'source-connection-not-found-description':
    'Please select an existing, source-enabled connection.',

  'source-semantic-entity-not-found-title': 'Invalid Entity',
  'source-semantic-entity-not-found-description': 'Please select an existing semantic entity.',

  'source-settings-not-valid-title': 'Invalid Connection Source Settings',
  'source-settings-not-valid-description':
    "The source settings are not valid according to the connector's requirements.",

  'too-many-source-column-title': 'Exceeded Maximum Column Limit',
  'too-many-source-column-description':
    'The number of columns has exceeded the maximum limit of 50. Please reduce the number of columns.',

  'deduplication-key-is-empty-title': 'Empty Records Key',
  'deduplication-key-is-empty-description':
    'The records key cannot be empty. Please select columns that will be used as key.',

  'source-have-no-column-title': 'No Columns Selected',
  'source-have-no-column-description':
    'A source should have at least one column. Please select the columns that will be imported to the source.',

  'essential-plan-can-only-fetch-from-brevo-source-title': 'Invalid Source Type',
  'essential-plan-can-only-fetch-from-brevo-source-description':
    'Essential offer can only fetch from Brevo source.',
};

const sourceRelationshipsErrors = {
  'mismatching-parent-and-child-datatype-title':
    'Data type mismatch in sources relationship columns',
  'mismatching-parent-and-child-datatype-description':
    'The data type of the referred source column does not match the other source column type.',
  'join-conditions-is-empty-title': 'Sources relationship columns Missing',
  'join-conditions-is-empty-description': 'No sources relationship columns have been specified.',
  'unmatched-parent-source-keys-title': 'Parent Source Column Key Linking Missing',
  'unmatched-parent-source-keys-description':
    'The keys of the parent source are missing links to child sources. Please ensure that all keys of the parent source are linked to the child source.',
  'join-condition-columns-not-found-title': 'Sources relationship columns not found',
  'join-condition-columns-not-found-description':
    'Selected columns for the relationship between sources were not found. Please select existing columns for the relationship.',
};

const datasetErrors = {
  'empty-dataset-name': 'Dataset name cannot be empty. Please provide a name for the dataset.',

  'dataset-name-is-empty-title': 'Empty Dataset Name',
  'dataset-name-is-empty-description':
    'Name field cannot be empty. Please provide a name for the dataset.',

  'dataset-name-is-too-long-title': 'Too Long Dataset Name',
  'dataset-name-is-too-long-description':
    'Dataset name cannot exceed the maximum length of 30 characters.',

  'dataset-name-duplicated-title': 'Duplicate Dataset Name',
  'dataset-name-duplicated-description':
    'This dataset name is already in use. Please choose a unique name.',

  'dataset-semantic-entity-not-found-title': 'Invalid Entity for Dataset',
  'dataset-semantic-entity-not-found-description':
    'Please select an existing semantic entity for the dataset.',

  'dataset-duplicated-semantic-entity-title': 'Conflicting Entity',
  'dataset-duplicated-semantic-entity-description':
    'Another dataset is already using this semantic entity. Please choose a different one.',

  'dataset-column-semantic-entity-not-found-title': 'Invalid Semantic Field for Dataset Column',
  'dataset-column-semantic-entity-not-found-description':
    'Please select an existing semantic field for this dataset column.',

  'dataset-duplicated-column-semantic-entity-title': 'Duplicate entity field in Dataset',
  'dataset-duplicated-column-semantic-entity-description':
    'Another column in the dataset is already using this entity field. Please choose a different one.',

  'dataset-mismatching-source-datatype-title': 'DataType Mismatch in Dataset and Source Column',
  'dataset-mismatching-source-datatype-description':
    "The data type of the referred connection source column does not match the dataset column. You can save but changes won't be deployed while the issue is not fixed.",

  'dataset-column-mismatch-semantic-field-datatype-title':
    'DataType Mismatch in Dataset Column and Semantic Field',
  'dataset-column-mismatch-semantic-field-datatype-description':
    "The data type of the column does not match the semantic field. You can save but changes won't be deployed while the issue is not fixed.",

  'dataset-deduplication-is-required-for-semantic-entity-title':
    'Deduplication is Required for this Semantic Entity',
  'dataset-deduplication-is-required-for-semantic-entity-description':
    'Deduplication is required for this semantic entity. Please select the deduplication keys.',

  'dataset-deduplication-keys-invalid-datatype-title': 'Invalid DataType for Deduplication Keys',
  'dataset-deduplication-keys-invalid-datatype-description':
    'The deduplication keys have an invalid data type. Please select columns with a valid data type for deduplication. Note that JSON and Array types are not accepted as deduplication keys.',

  'essential-plan-cannot-set-deduplication-settings-title': 'Invalid Deduplication Settings',
  'essential-plan-cannot-set-deduplication-settings-description':
    'Essential offer cannot set deduplication default settings.',
};

const columnErrors = {
  'column-name-not-valid-title': 'Column Name Needs Adjustment',
  'column-name-not-valid-description':
    "The column name needs normalization. Please ensure it is not empty, has a maximum of 50 characters, consists of lowercase letters, numbers, and underscores only. Also, make sure it doesn't begin or end with a double-underscore.",

  'column-name-duplicated-title': 'Duplicate Column Name',
  'column-name-duplicated-description':
    'This column name is already in use. Please choose a unique name.',
};

const transformedColumnsErrors = {
  'column-transformation-normalizePhoneNumber-countryCode-column-not-found-error-title':
    'Column not found.',
  'column-transformation-normalizePhoneNumber-countryCode-column-not-found-error-description':
    'The selected column for the country code does not exist anymore.',

  'column-transformation-normalizePhoneNumber-countryCode-column-type-mismatch-error-title':
    'Type Mismatch',
  'column-transformation-normalizePhoneNumber-countryCode-column-type-mismatch-error-description':
    'The selected column for the country code is not of type String.',

  'column-transformation-cyclic-dependency-error-title': 'Cyclic Dependency Detected',
  'column-transformation-cyclic-dependency-error-description':
    'The selected column has a cyclic dependency on the current column, creating an infinite loop.',
};

const calculatedColumnsErrors = {
  'calculated-column-syntax-error-title': 'Syntax Error',
  'calculated-column-syntax-error-description':
    'There seems to be a syntax error in your calculated column: "{{reason}}" from {{start}} to {{end}}.',

  'calculated-columns-cyclic-dependency-title': 'Cyclic Dependency Detected',
  'calculated-columns-cyclic-dependency-description':
    'Your calculated columns have a cyclic dependency, creating an infinite loop: {{names}}.',

  'calculated-column-syntax-not-supported-title': 'Unsupported Syntax',
  'calculated-column-syntax-not-supported-description':
    'The syntax used in your calculated column is not supported. Reason: {{reason}} At position {{start}}.',

  'calculated-column-reference-not-found-title': 'Field does not exist',
  'calculated-column-reference-not-found-description':
    'The field "{{reference}}" that you use in your formula at position {{start}} does not exist.',

  'calculated-column-reference-uncompilable-title': 'Uncompilable Reference',
  'calculated-column-reference-uncompilable-description':
    'The reference "{{reference}}" in your calculated column couldn\'t be compiled, at position {{start}}.',

  'calculated-column-type-mismatch-title': 'Type Mismatch',
  'calculated-column-type-mismatch-description':
    'There is a type mismatch in your calculated column. Reason: {{reason}} At position {{start}}.',
};

const scoreErrors = {
  'no-relationships-score-requirement-violation-title': 'No Relationship Violation',
  'no-relationships-score-requirement-violation-description':
    'It seems that the relationship required for the specified score do not exist. Please ensure that you defined the relationship between {{parent}} and {{child}} entities.',

  'no-dataset-score-requirement-violation-title': 'No Dataset Violation',
  'no-dataset-score-requirement-violation-description':
    'This error occurs when the dataset required for the specified score does not exist. To resolve this, make sure you have the dataset for semantic entity {{entity}} defined in your resources.',

  'no-dataset-column-score-requirement-violation-title': 'No Dataset Column Violation',
  'no-dataset-column-score-requirement-violation-description':
    'It appears that the dataset column required for the specified score does not exist. To address this issue, ensure that you defined the column for semantic field {{field}} of dataset for semantic entity {{entity}}.',
};

const aggregateErrors = {
  'aggregate-current-dataset-does-not-exist':
    'The current dataset does not exist. Please select a valid dataset.',
  'aggregate-invalid-dataset-path-title': 'Dataset path is invalid.',
  'aggregate-invalid-dataset-path': `The dataset path is invalid. Possible reasons include a non-existent target column in the dataset, filters not matching the target column values, or an incorrect/inaccessible dataset path.`,
  'aggregate-duplicate-column-id':
    'Duplicate column ID has been specified. Please ensure each column ID is unique.',
  'aggregate-duplicate-column-name':
    'Duplicate column name. Please ensure each column name is unique.',
  'aggregate-filter-invalid-dataset-path':
    'The specified dataset path is invalid. Please provide a valid dataset path.',
  'aggregated-column-filter-column-not-found':
    'The column specified in the filter does not exist. Please select an existing column.',
  'aggregated-column-filter-column-type-not-supported':
    'The column type specified in the filter is not supported. Please choose a column with a supported type.',
  'count-aggregate-column-not-found':
    'The specified column for the count aggregate does not exist. Please select an existing column.',
  'average-aggregate-column-not-found': 'Average aggregate column not found',
  'average-aggregate-column-type-not-supported': 'Unsupported type for average aggregate column',
  'sum-aggregate-column-not-found': 'Sum aggregate column not found',
  'sum-aggregate-column-type-not-supported': 'Unsupported type for sum aggregate column',
  'first-value-aggregate-selected-column-not-found':
    'Selected column not found for first value aggregate',
  'first-n-values-aggregate-selected-column-not-found':
    'Selected column not found for first N values aggregate',
  'first-n-values-aggregate-column-order-by-column-not-found':
    'Order by column not found for first N values aggregate',
  'first-n-values-aggregate-column-order-by-column-type-not-supported':
    'Unsupported type for order by column in first N values aggregate',
  'first-n-values-aggregate-invalid-number-of-values':
    'Invalid number of values specified for first N values aggregate',
  'first-value-aggregate-column-order-by-column-column-not-found':
    'Order by column not found for first value aggregate',
  'first-value-aggregate-column-order-by-column-type-not-supported':
    'Unsupported type for order by column in first value aggregate',
  'last-n-values-aggregate-selected-column-not-found':
    'Selected column not found for last N values aggregate',
  'last-n-values-aggregate-column-order-by-column-not-found':
    'Order by column not found for last N values aggregate',
  'last-n-values-aggregate-column-order-by-column-type-not-supported':
    'Unsupported type for order by column in last N values aggregate',
  'last-n-values-aggregate-invalid-number-of-values':
    'Invalid number of values specified for last N values aggregate',
  'last-value-aggregate-selected-column-not-found':
    'Selected column not found for last value aggregate',
  'last-value-aggregate-column-order-by-column-column-not-found':
    'Order by column not found for last value aggregate',
  'last-value-aggregate-column-order-by-column-type-not-supported':
    'Unsupported type for order by column in last value aggregate',
  'most-frequent-value-aggregate-selected-column-not-found':
    'Selected column not found for most frequent value aggregate',
  'nth-value-aggregate-selected-column-not-found':
    'Selected column not found for Nth value aggregate',
  'nth-value-aggregate-column-order-by-column-not-found':
    'Order by column not found for Nth value aggregate',
  'nth-value-aggregate-column-order-by-column-type-not-supported':
    'Unsupported type for order by column in Nth value aggregate',
  'nth-value-aggregate-invalid-rank': 'Invalid rank specified for Nth value aggregate',
};

const syncErrors = {
  'sync-name-empty-title': 'Empty Sync Name',
  'sync-name-empty-description':
    'Name field cannot be empty. Please provide a name for the sync operation.',
  'sync-name-duplicated-title': 'Duplicate Sync Name',
  'sync-name-duplicated-description':
    'This sync name is already in use. Please choose a unique name for the sync operation.',

  'sync-name-is-too-long-title': 'Too Long Sync Name',
  'sync-name-is-too-long-description':
    'Sync name cannot exceed the maximum length of 30 characters.',

  'sync-connection-not-found-title': 'Invalid Connection',
  'sync-connection-not-found-description':
    'Please select an existing connection for the sync operation.',

  'sync-connection-is-immutable-title': 'Connection Is Immutable',
  'sync-connection-is-immutable-description': "Sync's destination connection cannot be changed.",

  'sync-destination-type-immutable-title': 'Immutable Destination Type',
  'sync-destination-type-immutable-description': 'The type of destination cannot be changed.',

  'sync-destination-settings-type-immutable-title': 'Immutable Destination Settings Type',
  'sync-destination-settings-type-immutable-description':
    'The settings type of the destination cannot be changed.',

  'sync-destination-settings-type-mismatch-connection-type-title':
    'Destination Settings Type Mismatch',
  'sync-destination-settings-type-mismatch-connection-type-description':
    'The settings type of the destination does not match the connection type.',

  'sync-api-destination-type-mismatch-connector-kind-title': 'API Destination Type Mismatch',
  'sync-api-destination-type-mismatch-connector-kind-description':
    'The type of API destination does not match the connector kind.',

  'sync-api-entity-type-not-found-title': 'Invalid API Entity Type',
  'sync-api-entity-type-not-found-description': 'Please select a valid API entity type.',

  'sync-api-entity-type-is-not-destination-title': 'API Entity Type Is Not Destination',
  'sync-api-entity-type-is-not-destination-description':
    'The selected API entity type cannot be used as a destination.',

  'sync-api-entity-settings-not-valid-title': 'Invalid API Entity Settings',
  'sync-api-entity-settings-not-valid-description':
    "The API entity settings are not valid according to the API's requirements.",

  'sync-api-entity-destination-settings-not-valid-title': 'Invalid API Entity Destination Settings',
  'sync-api-entity-destination-settings-not-valid-description':
    "The API entity's destination settings are not valid according to the API's requirements.",

  'sync-api-entity-settings-immutable-title': 'Immutable API Entity Settings',
  'sync-api-entity-settings-immutable-description':
    'The settings of the API entity cannot be modified or updated.',

  'sync-api-key-field-is-immutable-title': 'Immutable API Key Field',
  'sync-api-key-field-is-immutable-description': 'The API key field cannot be modified or updated.',

  'sync-api-key-field-is-mandatory-for-entity-title': 'API Key Field is Mandatory for Entity',
  'sync-api-key-field-is-mandatory-for-entity-description':
    'The API key field is mandatory for this entity type. Please provide a valid API key field.',

  'sync-api-entity-type-immutable-title': 'Immutable API Entity Type',
  'sync-api-entity-type-immutable-description':
    'The selected API entity type cannot be modified or updated.',

  'sync-delete-strategy-not-supported-by-entity-title': 'Delete Strategy Not Supported by Entity',
  'sync-delete-strategy-not-supported-by-entity-description':
    'The selected delete strategy is not supported by the entity type.',

  'sync-destination-fields-duplicated-title': 'Duplicate Destination Fields',
  'sync-destination-fields-duplicated-description':
    'This destination field name is already mapped. Please ensure each field is mapped once.',

  'sync-dataset-immutable-title': 'Immutable Dataset',
  'sync-dataset-immutable-description': 'The selected dataset cannot be modified or updated.',

  'sync-segment-condition-not-valid-title': 'Invalid Segment Condition',
  'sync-segment-condition-not-valid-description': 'Provided segment condition is not valid.',

  'essential-plan-can-sync-only-contacts-entity-title': 'Invalid Destination Entity',
  'essential-plan-can-sync-only-contacts-entity-description':
    'Essential offer can sync only contacts entity.',

  'essential-plan-can-only-sync-to-brevo-destination-title': 'Invalid Destination Type',
  'essential-plan-can-only-sync-to-brevo-destination-description':
    'Essential offer can sync only to Brevo destination.',
};

const status = {
  'deployment-pending': 'Pending deployment',
  'deployment-in-progress': 'Deployment is in progress',
  'deployment-failed': 'Deployment failed',

  'execution-enqueued': 'Pending execution',
  'execution-in-progress': 'On going execution',
  'execution-failed': 'Execution failed',
  'execution-completed': 'Execution completed',
};

const resources = {
  ...resourceTypes,
  ...resourceErrors,
  ...connectionSourceErrors,
  ...sourceRelationshipsErrors,
  ...datasetErrors,
  ...columnErrors,
  ...transformedColumnsErrors,
  ...calculatedColumnsErrors,
  ...scoreErrors,
  ...status,
  ...syncErrors,
  ...aggregateErrors,
};

export default resources;
